/**
 * Timetastic
 * Leave management system page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import SignupButton from "src/components/signupButton"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/landing.scss"
import "src/styles/animations/landing.scss"

// SEO
const title = "Leave management system for hard working teams"
const description =
  "Leave management system for hard working teams. Timetastic is online software that helps you manage and record absence in your business"

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is annual leave management software?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Annual leave management software is a dynamic online system designed to help businesses of all sectors and sizes. Save time on admin and manually checking outdated spreadsheets with <a href='https://timetastic.co.uk'>Timetastic</a>. Our purpose-built leave management system can be integrated with existing calendars, such as <a href='https://timetastic.co.uk/blog/google-calendar-leave-management/'>Google’s annual leave calendar</a>. Don’t waste anymore valuable time filling out messy trackers that could be better spent growing your business when you can invest in annual leave software.",
        },
      },
      {
        "@type": "Question",
        name: "How can I approve annual leave requests using the leave management system?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Approving leave requests is as simple as requesting them. You can assign who the approver is and amend this at any time should you need to reallocate duties in your absence. Head into Timetastic to look at a list of pending requests and see if any of them clash with someone else’s time off. You can also receive and approve requests by email.",
        },
      },
      {
        "@type": "Question",
        name: "How can I see the annual leave allowance for each employee?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The wallchart offers total transparency for all staff, giving you a clear picture of everybody’s remaining annual leave entitlements. You can encourage staff to take time off at certain times to ensure your business is well-resourced throughout the entire year.",
        },
      },
      {
        "@type": "Question",
        name: "What type of staff leave can I manage?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Manage all types of leave seamlessly with our easy-to-use dashboard. Record <a href='https://timetastic.co.uk/blog/maternity-leave-what-are-you-entitled-to'>maternity</a>, <a href='https://timetastic.co.uk/blog/a-guide-to-paternity-leave-in-the-uk'>paternity</a>, <a href='https://timetastic.co.uk/blog/compassionate-leave-bereavement-leave'>compassionate and bereavement leave</a>, anything you want. Create as many employee leave types as you need!",
        },
      },
      {
        "@type": "Question",
        name: "What programs and apps can I integrate with the system?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The great thing about using Timetastic as your annual leave management software is the various integrations that you can easily set up. There’s Google Calendar, Outlook Calendar, G Suite, Apple Calendar, Slack, and Microsoft Teams. So you don’t always have to log in to see if someone’s off — you can just reference whatever calendar you normally use. It keeps things easy.",
        },
      },
      {
        "@type": "Question",
        name: "More questions?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Take a look at our <a href='https://help.timetastic.co.uk/hc/en-us'>Help Centre</a> for detailed articles on getting the most for Timetastic or contact our friendly <a href='https://timetastic.co.uk/support/'>Support Team</a>.",
        },
      },
    ],
  }

  return (
    <>
      <SEO title={title} description={description} schemaMarkup={schema} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <section
            className="c-section c-landing-screenshots is-unanimated c-landing-content c-landing-bullet"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-landing-screenshots__inner">
              <div>
                <h1 className="h h2 c-landing-screenshots__title">
                  Simplify holiday bookings with a{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    leave management system
                  </span>
                </h1>
                <p>
                  Embrace a holistic approach to managing annual leave. Track
                  your employees’ holiday allowance using Timetastic’s leave
                  management system — the best way to track time off for
                  businesses of all sizes.
                </p>
                <div className="c-landing-cta">
                  <SignupButton
                    buttonCopy="Start a free trial today"
                    trackCtaLabel="LeaveManagementHeroSignUp"
                  />
                  <span className="c-free-trial__no-link">
                    Free for one month — no card required
                  </span>
                </div>
              </div>

              <div className="c-landing-screenshots__images is-unanimated">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-landing-screenshots__tablet"
                  />
                  <StaticImage
                    src="../images/hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-landing-screenshots__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-landing-screenshots__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>
          <section className="c-section c-landing-bullet">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated">
                <h2 className="h h2 u-text-centre-desktop">
                  Paperless and{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    practical
                  </span>
                </h2>
              </div>

              <div className="c-feature c-feature--calendars">
                <div className="is-unanimated">
                  <div className="c-feature--calendars__blob-01">
                    <BackgroundBlobs blobType="howCalendarBlobs">
                      <div className="c-feature-calendars-grid__item">
                        <StaticImage
                          src="../images/calendars-months.jpg"
                          width={619}
                          alt="Calendar months"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-grid__annotation c-feature-calendars-grid__image-months c-home-calendars-grid__image"
                        />
                        <StaticImage
                          src="../images/calendars-months-2.jpg"
                          width={627}
                          alt="Calendar months"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-grid__annotation c-feature-calendars-grid__image-months-2 c-home-calendars-grid__image"
                        />
                        <StaticImage
                          src="../images/calendars-summary.jpg"
                          width={306}
                          alt="Leave summary"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-grid__annotation c-feature-calendars-grid__image-summary c-home-calendars-grid__image"
                        />
                        <StaticImage
                          src="../images/calendars-breakdown.jpg"
                          width={306}
                          alt="Your time off"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-feature-calendars-grid__image c-feature-calendars-grid__image-breakdown"
                        />
                        <StaticImage
                          src="../images/calendars-trends.jpg"
                          width={306}
                          alt="Annual trends"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-feature-calendars-grid__image c-feature-calendars-grid__image-trends"
                        />
                      </div>
                    </BackgroundBlobs>
                  </div>
                </div>
                <div className="is-unanimated c-landing-bullet">
                  <p>
                    With Timetastic’s leave management system, you’ll get
                    seamless updates and total transparency across your entire
                    business. Employees can request leave online or via our
                    handy mobile app — with leave managed in a matter of
                    seconds!
                  </p>
                  <ul>
                    <li>Submit time off requests instantly.</li>
                    <li>Approve and deny annual leave requests.</li>
                    <li>
                      Track individual annual leave allowances to ensure you’re
                      covered.
                    </li>
                    <li>
                      A tailored individual calendar for every staff member.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="c-feature c-feature__notifications">
                <div className="is-unanimated">
                  <h3 className="h h3">Fast updates and enhanced visibility</h3>
                  <p>
                    With our leave management system, you’ll never not be in the
                    know! Our interactive wallchart provides instant visibility
                    with real-time updates. And, if that’s not enough — you can
                    also set up email and app notifications, so you can accept
                    or decline leave requests immediately.
                  </p>
                  <p>
                    If multiple requests for the same period are submitted,
                    you’ll be able to see and manage leave requests accordingly
                    so you aren’t short-staffed.
                  </p>
                  <div className="c-landing-cta">
                    <SignupButton
                      buttonCopy="Get a free trial"
                      trackCtaLabel="LeaveManagementSignUp"
                    />
                    <span className="c-free-trial__no-link">
                      Free for one month — no card required
                    </span>
                  </div>
                </div>
                <div className="c-feature-notifications-grid__item">
                  <StaticImage
                    src="../images/notifications-request.jpg"
                    width={249}
                    alt="Request notification"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request"
                  />
                  <StaticImage
                    src="../images/notifications-approval.jpg"
                    width={249}
                    alt="Approval notification"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval"
                  />
                  <StaticImage
                    src="../images/notifications-request.jpg"
                    width={464}
                    alt="Request notification"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request-s"
                  />
                  <StaticImage
                    src="../images/notifications-approval.jpg"
                    width={464}
                    alt="Approval notification"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval-s"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-landing-gradient__grey">
            <div className="u-inner u-inner--l is-unanimated c-landing">
              <div className="u-text-center">
                <h2 className="h h1">Using your leave management wallchart</h2>
                <p>
                  When signing up for Timetastic, the first thing you’ll see is
                  your wallchart.
                </p>
              </div>
              <div className="c-home-grid__annotation">
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                  Your team
                </span>
                <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                  Book time off
                </span>
                <StaticImage
                  src="../images/live-wallchart.jpg"
                  width={1200}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart"
                />
                <StaticImage
                  src="../images/live-wallchart-mobile.jpg"
                  width={440}
                  alt="Wallchart"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart-s"
                />
                <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                  Pending request
                </span>
              </div>
            </div>
            <div className="u-inner u-inner--800 is-unanimated c-landing">
              <div className="is-unanimated c-landing-bullet c-landing-content">
                <p>&nbsp;</p>
                <p>
                  The wallchart gathers a lot of important info in one place, so
                  you don’t have to use multiple screens or rely on
                  spreadsheets, emails, and Slack messages to streamline your
                  leave management.
                </p>
                <p>
                  Using our annual leave management software, you’ll have total
                  transparency on:
                </p>
                <ul>
                  <li>
                    Who has which days off and what type of leave they’re taking
                    — such as holiday or sick pay.
                  </li>
                  <li>
                    The annual leave allowance that each person has left. You
                    can use our handy{" "}
                    <a
                      aria-label="The Burnout Board"
                      href="https://help.timetastic.co.uk/hc/en-us/articles/360015746318-The-Burnout-Board"
                      onClick={() => {
                        trackCta("LeaveManagementBurnoutBoard")
                      }}
                    >
                      Burnout Board
                    </a>{" "}
                    that shows you who hasn’t taken time off in a while.
                  </li>
                  <li>
                    Clear visibility on any pending requests, so you can see in
                    an instant who has requested leave, what type of leave and
                    when.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="c-section c-landing-gradient__green c-landing-content">
            <div className="u-inner u-inner--l is-unanimated ">
              <h2 className="h h2">Annual leave management system features</h2>
              <div className="c-landing-seo">
                <div>
                  <p>
                    Sign up for Timetastic’s leave management system and get the
                    following benefits:
                  </p>
                  <h3 className="h h3">Manage group bookings</h3>
                  <p>
                    Book time off for several people at the same time in the
                    event of a training day or staff event.
                  </p>
                  <h3 className="h h3">Handy lock dates</h3>
                  <p>
                    Use our lock dates feature to mark days when you need all
                    staff at work on a certain day or period.
                  </p>
                  <h3 className="h h3">Stay on top of TOIL</h3>
                  <p>
                    Easily add{" "}
                    <a
                      aria-label="What is TOIL? (with good examples)"
                      href="https://timetastic.co.uk/blog/what-is-toil/"
                      onClick={() => {
                        trackCta("LeaveManagementToil")
                      }}
                    >
                      time off in lieu
                    </a>{" "}
                    to your employees’ calendars for enhanced visibility.
                  </p>
                  <h3 className="h h3">Integrate with your calendars</h3>
                  <p>
                    Sync your leave management system with your calendar for
                    visibility without needing to log in.
                  </p>
                  <h3 className="h h3">
                    Personalised leave management for everybody
                  </h3>
                  <p>
                    An individual staff holiday calendar for each employee with
                    real-time updates for maximum transparency.
                  </p>
                  <h3 className="h h3">Group employees by department</h3>
                  <p>
                    Group employees by department, set minimum staffing levels
                    and highlight any clashes before approving any leave
                    requests.
                  </p>
                </div>
                <div className="c-landing-seo__media">
                  <a
                    href="https://www.capterra.com/reviews/146655/Timetastic?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="capterra"
                  >
                    {" "}
                    <img
                      loading="lazy"
                      border="0"
                      src="https://assets.capterra.com/badge/3fb93b4fa79a460bf121a4febd992c0c.png?v=2103571&p=146655"
                    />
                  </a>
                  <a
                    href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="https://media.reviews.co.uk/badge/timetastic.png"
                    />
                  </a>
                  <a
                    href="https://uk.trustpilot.com/review/timetastic.co.uk"
                    rel="nofollow noreferrer noopener"
                    target="_blank"
                    className="reviews-io"
                  >
                    <img
                      loading="lazy"
                      src="/images/features/social-proof/trustpilot-badge.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-section c-home-reviews u-text-center u-text-left-mobile"
            id="reviews"
          >
            <div className="u-inner is-unanimated">
              <h2 className="h h2 c-home-reviews__title">
                Discover how we{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  transformed leave management
                </span>{" "}
                for our customers
              </h2>
            </div>
            <div className="u-inner u-inner--800 is-unanimated c-landing">
              <p>
                Over 10,000 businesses use Timetastic as their go-to annual
                leave management software for tracking annual leave.{" "}
                <a
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                  aria-label="Capterra reviews"
                  href="https://www.capterra.com/p/146655/Timetastic/"
                >
                  Capterra
                </a>{" "}
                rates Timetastic’s leave management system with a rating of 4.7
                out of 5 stars, with 552 reviews. Check out what some of our
                customers have had to say:
              </p>
            </div>
            <div className="u-inner u-inner--l c-landing">
              <section className="c-section c-landing-quotes">
                <div className="u-inner u-inner--l">
                  <div className="c-landing-quote">
                    <div className="c-landing-quote__head">
                      <p>
                        Timetastic was a big step up from our previous
                        spreadsheet leave system. As the team expanded, it made
                        it easy for us to have a ‘proper’ approval system for
                        leave, avoid clashes between critical staff and scrap
                        the rubbish paper calendar on the wall that no one ever
                        bothered to look at! Our leave booking is now simple,
                        and there is no excuse for forgetting to mark your
                        holiday. The calendar sharing means I can see at a
                        glance who is out of the office. We also use Timetastic
                        for sick leave and tracking who is out of the office for
                        sales trips and meetings (an extra bonus).
                      </p>
                    </div>
                    <div className="c-landing-quote__body">
                      <div className="c-landing-quote__person">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.capterra.com/p/146655/Timetastic/reviews/1069715/"
                        >
                          Read Andy’s full review here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="c-landing-quote">
                    <div className="c-landing-quote__head">
                      <p>
                        Timetastic has a simple, easy-to-use interface. This
                        enabled us to delete some boring and complicated
                        spreadsheets that were hard to maintain. Hooray! New
                        starters require no introduction, and admins can quickly
                        get on with this often tedious process. The Slack
                        integration enables us to effortlessly manage and track
                        who’s on leave. I think Timetastic is of great value and
                        have recommended it to other small business owners I
                        know.
                      </p>
                    </div>
                    <div className="c-landing-quote__body">
                      <div className="c-landing-quote__person">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.capterra.com/p/146655/Timetastic/reviews/1069866/"
                        >
                          Read Kate’s full review here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section className="c-section c-landing-faq c-landing-content">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h2">Leave management system FAQs</h2>
              <ul>
                <li>
                  <h3 className="h h4">
                    What is annual leave management software?
                  </h3>
                  <p>
                    Annual leave management software is a dynamic online system
                    designed to help businesses of all sectors and sizes. Save
                    time on admin and manually checking outdated spreadsheets
                    with{" "}
                    <a
                      aria-label="Switching to Timetastic makes booking time off work a breeze"
                      href="https://timetastic.co.uk"
                      onClick={() => {
                        trackCta("LeaveManagementFaq")
                      }}
                    >
                      Timetastic
                    </a>
                    . Our purpose-built leave management system can be
                    integrated with existing calendars, such as{" "}
                    <a
                      aria-label="The best Google Calendar leave management system"
                      href="https://timetastic.co.uk/blog/google-calendar-leave-management/"
                      onClick={() => {
                        trackCta("LeaveManagementFaqGoogle")
                      }}
                    >
                      Google’s annual leave calendar
                    </a>
                    . Don’t waste anymore valuable time filling out messy
                    trackers that could be better spent growing your business
                    when you can invest in annual leave software.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    How can I approve annual leave requests using the leave
                    management system?
                  </h3>
                  <p>
                    Approving leave requests is as simple as requesting them.
                    You can assign who the approver is and amend this at any
                    time should you need to reallocate duties in your absence.
                    Head into Timetastic to look at a list of pending requests
                    and see if any of them clash with someone else’s time off.
                    You can also receive and approve requests by email.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    How can I see the annual leave allowance for each employee?
                  </h3>
                  <p>
                    The wallchart offers total transparency for all staff,
                    giving you a clear picture of everybody’s remaining annual
                    leave entitlements. You can encourage staff to take time off
                    at certain times to ensure your business is well-resourced
                    throughout the entire year.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    What type of staff leave can I manage?
                  </h3>
                  <p>
                    Manage all types of leave seamlessly with our easy-to-use
                    dashboard. Record{" "}
                    <a
                      aria-label="Maternity leave — a short guide to what you’re entitled to"
                      href="https://timetastic.co.uk/blog/maternity-leave-what-are-you-entitled-to"
                      onClick={() => {
                        trackCta("LeaveManagementFaqMaternity")
                      }}
                    >
                      maternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="A Guide to Paternity Leave in the UK"
                      href="https://timetastic.co.uk/blog/a-guide-to-paternity-leave-in-the-uk"
                      onClick={() => {
                        trackCta("LeaveManagementFaqPaternity")
                      }}
                    >
                      paternity
                    </a>
                    ,{" "}
                    <a
                      aria-label="How do compassionate leave and bereavement leave work?"
                      href="https://timetastic.co.uk/blog/compassionate-leave-bereavement-leave"
                      onClick={() => {
                        trackCta("LeaveManagementFaqCompassionate")
                      }}
                    >
                      compassionate and bereavement leave
                    </a>
                    , anything you want. Create as many employee leave types as
                    you need!
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    What programs and apps can I integrate with the system?
                  </h3>
                  <p>
                    The great thing about using Timetastic as your annual leave
                    management software is the various integrations that you can
                    easily set up.
                  </p>
                  <p>
                    There’s Google Calendar, Outlook Calendar, G Suite, Apple
                    Calendar, Slack, and Microsoft Teams. So you don’t always
                    have to log in to see if someone’s off — you can just
                    reference whatever calendar you normally use. It keeps
                    things easy.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">More questions?</h3>
                  <p>
                    Take a look at our{" "}
                    <a
                      href="https://help.timetastic.co.uk/hc/en-us"
                      rel="noopener"
                      onClick={() => {
                        trackCta("LeaveManagementFaqHelp")
                      }}
                    >
                      Help Centre
                    </a>{" "}
                    for detailed articles on getting the most for Timetastic or
                    contact our friendly{" "}
                    <Link
                      to="/support/"
                      onClick={() => {
                        trackCta("LeaveManagementFaqSupport")
                      }}
                    >
                      <span>Support Team</span>
                    </Link>
                    .
                  </p>
                </li>
              </ul>
            </div>
          </section>

          <section className="is-unanimated c-section u-text-center c-free-trial c-free-trial--gradient-bottom">
            <div className="u-inner">
              <h2 className="h h2 c-free-trial__title u-text-left-mobile">
                Happier with Timetastic
              </h2>
              <div className="u-inner c-free-trial__copy u-text-left-mobile">
                <Link
                  className="u-em-link"
                  to="/customers/"
                  onClick={() => {
                    trackCta("LeaveManagementCustomers")
                  }}
                >
                  <span>Read how people like you use Timetastic</span>
                </Link>
                <p>
                  Their stories tell us how they took control of absence
                  management and became healthier, happier places to work
                </p>
                <h2 className="h h4">Want to see how it works?</h2>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://timetastic.ewebinar.com/webinar/timetastic-product-demo-6394"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("eWebinar")
                  }}
                >
                  <span>Watch our 10 minute webinar</span>
                </a>
                {/* <span className="u-text-left-mobile"> or </span>
                <a
                  className="u-em-link u-text-left-mobile"
                  href="https://calendly.com/sarah-timetastic/timetastic-demo"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("BookDemo")
                  }}
                >
                  <span>book a live demo</span>
                </a> */}
              </div>
            </div>
          </section>

          <section className="c-section u-text-centre u-text-left-mobile c-landing-apps">
            <div className="u-inner u-inner--ms is-unanimated">
              <h2 className="h h2">
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  Mobile apps
                </span>{" "}
                too
              </h2>
              <p>
                Your team can make use of the leave management software apps for
                Android and Apple to book an absence. No need to be in the
                office, everything is instantly tracked!
              </p>
            </div>
            <div className="u-inner u-inner--ms">
              <ul className="c-badge-list is-unanimated">
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://itunes.apple.com/gb/app/timetastic-nicer-way-to-book/id633985417?mt=8"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("iOSappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Download on App Store"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/app-store.png"
                      width="252"
                    />
                  </a>
                </li>
                <li className="c-badge-list__item">
                  <a
                    className="c-badge-list__link"
                    href="https://play.google.com/store/apps/details?id=com.mediaburst.timetastic&hl=en"
                    rel="noreferrer noopener"
                    target="_blank"
                    onClick={() => {
                      trackCta("Androidappclick")
                    }}
                  >
                    <img
                      loading="lazy"
                      alt="Get it on Google Play"
                      className="c-badge-list__image"
                      height="84"
                      src="/images/badges/google-play.png"
                      width="282"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <FreeTrial
            body="Timetastic has been helping people book their holidays since 2012. Doctors, web design agencies, car dealers, online retailers, charities — over 8,000 teams and small businesses just like yours."
            buttonCopy="Start booking holidays now"
            title="200,000+ people use Timetastic"
            trackCtaLabel="LeaveManagementSignUpFooter"
            imgClass="c-free-trial__user-graph"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
